<template>
  <div>
    <base-header type="gradient-warning" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row class="justify-content-center">
        <b-col md="6">
          <card>
            <VeeObserver ref="form" slim>
              <VeeProvider name="name" rules="required" v-slot="{ errors }">
                <base-input
                  class="input-group-alternative"
                  placeholder="Name"
                  addon-left-icon="fa fa-trademark"
                  v-model="payload.name"
                  :error="errors[0]"
                />
              </VeeProvider>
              <VeeProvider name="image" rules="required|length:1" v-slot="{ errors }">
                <b-form-group label="Image(Trademark)">
                  <FileUpload v-model="payload.image" ref="image" />
                  <small class="text-danger" v-if="errors.length">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </VeeProvider>
              <div class="text-center">
                <base-button
                  variant="primary"
                  type="submit"
                  class="my-4"
                  @click="isEdit ? update() : create()"
                  :loading="loading"
                >
                  <i class="fa fa-save"></i> {{ isEdit ? "Update" : "Create" }}
                </base-button>
              </div>
            </VeeObserver>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BrandsService from "features/brands/services/BrandsService";

import { resource } from "helpers";

export default {
  name: "FormBrands",
  data: () => ({
    loading: false,
    payload: {
      name: "",
      image: [],
    },
  }),
  computed: {
    isEdit() {
      return this.$route.name === "brands-edit";
    },
  },
  mounted() {
    if (this.isEdit) this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await BrandsService.show(this.$route.params.id);
        this.payload.name = data.name;
        this.payload.image = [resource(data.image)];
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async create() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, image } = this.payload;
          await BrandsService.create({
            name,
            image: {
              type: image[0].ext,
              value: image[0].item,
            },
          });
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/brands"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, image } = this.payload;
          const payload = { name };
          if (image[0].constructor.name === "Object") {
            payload.image = {
              type: image[0].ext,
              value: image[0].item,
            };
          }
          await BrandsService.update(payload, this.$route.params.id);
          this.$swal({
            icon: "success",
            title: "Record updated successfully",
          }).then(() => this.$router.push("/brands"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
